class Config{
    constructor(){
        this.env = 'sandbox';
        this.backend = 'https://api.sandbox.mrm.bpce.scripters.fr';
        this.kms = 'https://kms.mrm.bpce.audience-pro.com/';
        this.api = this.backend + '/api';
        this.notificationRefreshInterval = 60 * 1000; // ms
        this.threadRefreshInterval = 20 * 1000; // ms
        this.httpDefaultCacheTimeout = 60 * 1000; //ms
    }
}

export default new Config();